<template>
  <button ref="propertyContentRef" class="PropertyContent" :class="{ isInteractive: !isNotInteractive, isPlaceholder }">
    <slot></slot>

    <PropertyActionIndicator v-if="action && isHovered" :action="action" style="margin-left: auto" />
  </button>
</template>

<script setup lang="ts">
import type { Action } from "./PropertyActionIndicator.vue";

defineProps<{ action?: Action; isNotInteractive?: boolean; isPlaceholder?: boolean }>();

const propertyContentRef = ref<HTMLElement>();

const isHovered = useElementHover(propertyContentRef);
</script>

<style scoped lang="scss">
.PropertyContent {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 32px;
  padding-inline: 8px;
  gap: 4px;
  transition: var(--transition-1);
  border-radius: 5px;
  flex: 1; /* Take full available width within flex container */
  overflow: hidden;
  flex-shrink: 0;

  &.isInteractive {
    cursor: pointer;

    &:hover {
      background: var(--gray-6);
    }

    &:active {
      background: var(--gray-5);
    }
  }

  &.isPlaceholder {
    color: var(--gray-3);
  }
}
</style>
