<template>
  <LucideIcon :icon="mapActionIcon[action]" class="PropertyActionIndicator" />
</template>

<script setup lang="ts">
import type { LucideIconName } from "~/components/lucide-icon/types";

export type Action = "edit" | "open" | "select" | "plus";

defineProps<{ action: Action }>();

const mapActionIcon: Record<Action, LucideIconName> = {
  edit: "Pencil",
  open: "ArrowUpRight",
  select: "ChevronDown",
  plus: "Plus",
};
</script>

<style lang="scss" scoped>
.PropertyActionIndicator {
  color: var(--gray-3);
}
</style>
